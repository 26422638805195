import React from 'react';
import { Icon, NavBar } from 'antd-mobile';
import styled from 'styled-components';
import Router, { withRouter } from 'next/router';

const handleReturn = () => {
  // const keys = Object.keys(Router.router.components);
  // const currentPath = Router.router.pathname;
  // const lastPath = keys[keys.length - 1];
  // if (currentPath === lastPath) {
  //   Router.push('/');
  //   return;
  // }
  if (Object.keys(Router.router.components).length > 2) {
    Router.back();
  } else {
    Router.push('/');
  }
};

const NavigationBar = props => {
  const { title, rightAction, leftAction, rightContent, className } = props;
  return (
    <div className={className}>
      <div className="fix">
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            if (leftAction) {
              return leftAction();
            } else {
              return handleReturn();
            }
          }}
          rightContent={
            typeof rightContent !== 'undefined'
              ? rightContent
              : [
                  <label key="0" onClick={rightAction}>
                    取消
                  </label>
                ]
          }
        >
          {title}
        </NavBar>
      </div>
      <div className="emptyBlock" />
    </div>
  );
};

const SMNavBar = styled(NavigationBar)`
  overflow: hidden;
  @media (min-width: ${props => props.theme.desktopMinWidth}) {
    .fix {
      width: ${props => props.theme.maxWidth};
    }
  }

  @media (max-width: ${props => props.theme.mobileMaxWidth}) {
    .fix {
      width: 100%;
      left: 0;
    }
  }
  .fix {
    position: fixed;
    top: 0;
    z-index: 2;
    .am-navbar-light {
      background-color: #ff8000;
      color: white;
      .am-navbar-title {
        color: white;
        text-overflow: ellipsis;
        max-width: 85%;
      }
    }
  }
  .emptyBlock {
    margin-top: 45px;
  }
`;

export default withRouter(SMNavBar);
