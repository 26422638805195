import axios from 'axios';
import CONFIG from './config';

// Instantiate axios
let _axios = null;

export function initializeAxios(isServer) {
  if (isServer || _axios === null) {
    _axios = axios.create({
      baseURL: CONFIG.backend.base
    });
  }
  return _axios;
}
