import * as Utils from '../util';
import { types } from 'mobx-state-tree';

export const MerchantModel = types.model('merchantModel', {
  id: types.maybeNull(types.union(types.string, types.number)),
  name: types.maybeNull(types.optional(types.string, '')),
  tags: types.maybeNull(types.optional(types.array(types.number), [])),
  logo: types.maybeNull(types.optional(types.string, '')),
  address: types.maybeNull(types.optional(types.string, '')),
  phone: types.maybeNull(types.optional(types.string, '')),
  email: types.maybeNull(types.string),
  website: types.maybeNull(types.optional(types.string, '')),
  contact: types.maybeNull(types.optional(types.string, '')),
  openTime: types.maybeNull(types.optional(types.string, '')),
  description: types.maybeNull(types.optional(types.string, '')),
  fileList: types.maybeNull(types.optional(types.array(types.string), [])),
  verified: types.maybeNull(types.boolean),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  category_level_1: types.maybeNull(types.string),
  category_level_2: types.maybeNull(types.string),
  company_id: types.maybeNull(types.number),
  category_name: types.maybeNull(types.string),
  images: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.number),
  ttTags: types.maybeNull(types.array(types.string)),
  pivot: types.maybeNull(
    types.model('pivotModel', {
      user_id: types.number,
      merchant_id: types.number,
      created_at: types.maybeNull(types.string),
      updated_at: types.maybeNull(types.string)
    })
  )
});

const MerchantStore = MerchantModel.actions(self => ({
  setAddress(value) {
    self.address = value;
  },
  setPhone(value) {
    self.phone = value;
  },
  setEmail(value) {
    self.email = value;
  },
  setWebsite(value) {
    self.website = value;
  },
  setContact(value) {
    self.contact = value;
  },
  setName(name) {
    self.name = name;
  },
  setTags(tags) {
    self.tags = tags;
  },
  setLogo(logoUrl) {
    self.logo = logoUrl;
  },
  setOpenTime(value) {
    self.openTime = value;
  },
  setDescription(value) {
    self.description = value;
  },
  setFileList(value) {
    self.fileList = value;
  },
  hasMandatoryInfo() {
    return (
      Utils.isNotEmpty(self.name) &&
      Utils.isNotEmpty(self.tags) &&
      Utils.isNotEmpty(self.logo)
    );
  },
  clear() {
    self.name = '';
    self.tags = [];
    self.logo = '';
    self.address = '';
    self.phone = '';
    self.email = '';
    self.website = '';
    self.contact = '';
    self.openTime = '';
    self.description = '';
    self.fileList = [];
  },
  initWithMerchantInfo(info) {
    self.name = info.name ? info.name : '';
    self.tags = info.tags ? info.tags : [];
    self.logo = info.logo ? info.logo : null;
    self.address = info.address ? info.address : '';
    self.phone = info.phone ? info.phone : '';
    self.email = info.email ? info.email : '';
    self.website = info.website ? info.website : '';
    self.contact = info.contact ? info.contact : '';
    self.openTime = info.open_time ? info.open_time : '';
    self.description = info.description ? info.description : '';
    self.ttTags = info.tt_tags ? info.tt_tags : [];
  }
}));

export default MerchantStore;
