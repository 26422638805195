import config from './config';
import cloudinary from 'cloudinary-core';
import urijs from 'urijs';

const avatarImg = '../static/avatar.png';
const shenmaDefaultImage = '../static/topic-default/shenma_default_image.PNG';

export const srcToImg = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    // if load from web https://github.com/alexk111/ngImgCrop/issues/28
    if (src.indexOf('http') === 0) {
      img.crossOrigin = 'anonymous';
    }
    img.onload = () => {
      resolve(img);
    };
    img.onabort = img.onerror = err => {
      reject(err);
    };

    if (src.indexOf('qlogo.cn') > 0) {
      //通过代理去访问微信图片
      img.src =
        config.domain + '/wechat_image/' + src.substring(src.indexOf('mmopen'));
    } else {
      img.src = src;
    }
  });

let cl = new cloudinary.Cloudinary({ cloud_name: 'shenmanz', secure: true });
//Banner
export const getImageHomeBanner = imageid => {
  return cl.url('shenma/banner/' + imageId, {
    width: 1200,
    height: 255,
    q_auto: 'best',
    fetch_format: 'auto'
  });
};

//手机端全屏图片
export const getImageMobileFull = imageId => {
  if (!imageId) return shenmaDefaultImage;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: 1200,
    height: 1800,
    crop: 'pad',
    background: 'black',
    q_auto: 'best',
    fetch_format: 'auto'
  });
};
//手机端列表页方图
export const getImageMobileList = imageId => {
  if (!imageId) return shenmaDefaultImage;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  const url = cl.url('shenma/' + imageId, {
    width: 450,
    height: 450,
    crop: 'fill',
    gravity: 'auto',
    q_auto: 'best',
    fetch_format: 'auto'
  });
  return url;
};

export const getImageByID = imageId => {
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, { fetch_format: 'auto' });
};

export const isCloudinary = url => {
  if (!url) return false;
  const urlObj = urijs(url);
  const hostname = urlObj.hostname();
  return hostname === 'res.cloudinary.com';
};

export const repairLogoUrl = url => {
  if (!isCloudinary(url)) return url;
  const imageId = url.split('shenma/')[1];
  if (imageId) {
    return cl.url('shenma/' + imageId, { fetch_format: 'auto' });
  } else {
    return url;
  }
};

export const getBannerImage = imageId => {
  if (!imageId) return null;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    q_auto: 'best',
    fetch_format: 'auto'
  });
};

export const getHomeBannerImage = imageId => {
  if (!imageId) return null;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    q_auto: 'best',
    fetch_format: 'auto'
  });
};

export const getGroupImage = imageId => {
  if (!imageId) return null;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: 120,
    height: 120,
    crop: 'fill',
    gravity: 'auto',
    q_auto: 'best',
    fetch_format: 'auto'
  });
};

export const getImage = (imageId, width, height) => {
  if (!imageId) return shenmaDefaultImage;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: width,
    height: height,
    crop: 'fill',
    gravity: 'auto',
    q_auto: 'best',
    fetch_format: 'auto'
  });
};

export const getFirstImageMobileList = imageId => {
  if (!imageId) return shenmaDefaultImage;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: 1200,
    height: 1200,
    crop: 'fill',
    gravity: 'auto',
    q_auto: 'best',
    fetch_format: 'auto'
  });
};
//手机端详情页方图
export const getImageMobileDetail = imageId => {
  if (!imageId) return shenmaDefaultImage;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: 1200,
    height: 1200,
    crop: 'lfill',
    gravity: 'auto',
    q_auto: 'best',
    fetch_format: 'auto'
  });
};
//手机端列表页方图
export const getImageAvatar = imageId => {
  if (!imageId) return avatarImg;
  if (/^http/i.test(imageId)) {
    return imageId;
  }
  return cl.url('shenma/' + imageId, {
    width: 150,
    height: 150,
    crop: 'crop',
    gravity: 'face',
    fetch_format: 'auto'
  });
};
//popup 弹窗广告图
export const getImagePopup = imageId => {
  return cl.url('shenma/' + imageId, {
    width: 520,
    height: 660,
    fetch_format: 'auto'
  });
};

export const transformImage = (rawName, size) => {
  if (rawName) {
    if (/^http/i.test(rawName)) {
      return rawName;
    } else {
      const { domain, name } = rawName;
      return `${domain}/${size}x${size}/${name}`;
    }
  } else {
    return shenmaDefaultImage;
  }
};

export function guid() {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

export const messagify = (error = {}) => {
  return Object.keys(error)
    .map(key => {
      return error[key].join(',');
    })
    .join(',');
};

export const getMobile = (prefix, mobile) =>
  prefix + (mobile || '').replace(/^[0]+/g, '').replace(/\s+/g, '');

// Format mobile number
export const formatMobile = mobile => {
  if (mobile) {
    const prefix = mobile.slice(0, 2);
    if (prefix === '64') {
      return '0' + mobile.slice(2).replace(/\s+/g, '');
    }
    if (prefix === '86') {
      return mobile.slice(2).replace(/\s+/g, '');
    }
  } else {
    return '';
  }
};

export const arrayToObj = arr => {
  return arr.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
  }, {});
};

export const objArrayToObj = objArr => {
  const obj = {};
  Object.keys(objArr).forEach(key => {
    obj[key] = arrayToObj(objArr[key]);
  });
  return obj;
};

export const searchToObj = search =>
  search
    .slice(1)
    .split('&')
    .reduce((sum, queries) => {
      const arr = queries.split('=');
      sum[arr[0]] = arr[1];
      return sum;
    }, {});

export const centToDollar = cent => (cent / 100).toFixed(2);

// combined city and area data for the picker component in ant design
export const combineCityAndArea = (city, area) => {
  city.forEach(item => {
    const cityId = item.id;
    if (area[cityId]) {
      item.children = area[cityId];
    } else {
      item.children = [];
    }
  });
  return city;
};

export const isEmpty = obj => {
  if (obj == null) return true;

  if (typeof obj === 'number') {
    return false;
  }

  if (obj instanceof Date) {
    return false;
  }

  if (typeof obj === 'string') {
    return obj.trim().length === 0;
  }

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const isNotEmpty = obj => {
  return !isEmpty(obj);
};

export const searchItemLabelbyValue = (list, value) => {
  if (!list || list.length === 0) {
    return '';
  }
  let key = '';
  list.forEach(item => {
    if (item.value === value) {
      key = item.label;
    }
  });
  return key;
};

export const readUploadedFileAsImage = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export const messageTimeFormat = date => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const msgDate = new Date(date.replace(/-/g, '/'));
  const msgYear = date.slice(0, 4);
  const msgMonthDay = date.slice(5, 10);
  const msgTime = date.slice(11, 16);
  if (currentDate.toDateString() === msgDate.toDateString()) {
    //today
    return msgTime;
  }
  if (currentYear === msgYear) {
    //this year
    return `${msgMonthDay}-${msgTime}`;
  }
  return `${msgYear}-${msgMonthDay}-${msgTime}`;
};

export const dateFormat = (date, format = 'yyyy年MM月dd日hh:mm') => {
  //eg:format="yyyy-MM-dd hh:mm:ss";
  var o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'H+': date.getHours(), // hour
    'h+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds()
  };

  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }

  return format;
};

export const strMapToObj = strMap => {
  let obj = Object.create(null);
  for (let [k, v] of strMap) {
    obj[k] = v;
  }
  return obj;
};
